import * as React from "react";
import { HtmlHead, Link } from "@cdv/jazz-web/components";
import { Typography, Box, Container } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";

export const Head = () => (
  <HtmlHead title="Odkazy | Centrum dopravního výzkumu, v. v. i.">
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content="Sit" lang="cs" />
    <meta name="keywords" content="sit" lang="cs" />
    <meta name="robots" content="index, follow" />
    <meta name="rating" content="general" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  </HtmlHead>
);

export default function Page() {
  return (
    <Layout>
      <Container>
        <ul>
          <li>
            <Link to="http://www.aksignal.cz/">http://www.aksignal.cz/</Link>
          </li>
          <li>
            <Link to="http://www.restrail.eu/">http://www.restrail.eu/</Link>
          </li>
          <li>
            <Link to="http://www.dicr.cz/">http://www.dicr.cz/</Link>
          </li>
          <li>
            <Link to="https://www.tacr.cz/">https://www.tacr.cz/</Link>
          </li>
        </ul>
      </Container>
    </Layout>
  );
}
